/**********************
Global Styles
**********************/
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap");
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #000;
  font-size: 17px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Quicksand", Helvetica, sans-serif;
  text-rendering: geometricPrecision;
  overflow-x: hidden; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 100px 0; }

button {
  border: 0;
  background-color: transparent; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

.container {
  max-width: 1140px; }

.section-title {
  font-size: 70px;
  color: #000;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0;
  text-rendering: geometricPrecision; }

.sub-heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 4px;
  text-transform: uppercase;
  color: #A9A8A9; }

strike {
  color: #797979; }

.img-container {
  text-align: center; }

.sale-text {
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

.check {
  height: 20px !important;
  margin-right: 4px !important;
  margin-bottom: 0 !important; }

.text-black {
  color: #000 !important; }

.border-white {
  border: 2px solid #fff;
  border-radius: 5px; }

.border-black {
  border: 2px solid #000;
  border-radius: 5px; }

.link-color {
  color: #2DA7DF;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .link-color:hover {
    color: #2DA7DF;
    opacity: .8; }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  box-shadow: none !important;
  outline: 0; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #000;
  background-color: #000;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: transparent;
  border: 2px solid transparent;
  color: #2DA7DF; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

/** MEDIA QUERIES **/
@media only screen and (max-width: 1199px) {
  .container {
    max-width: 1060px; } }

@media only screen and (max-width: 991px) {
  section {
    padding: 50px 0; }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:active,
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:visited {
    font-size: 12px; }
  .container {
    max-width: 890px; }
  .section-title {
    font-size: 38px; } }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; }
  .container {
    max-width: 100%; }
  .mobile-menu img {
    height: auto; }
  .mobile-menu input + label {
    right: 15px; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 30px; }
  p {
    font-size: 16px; } }

@media only screen and (max-width: 475px) {
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media only screen and (max-width: 450px) {
  section {
    padding: 50px 0; }
  .sub-heading {
    font-size: 18px;
    line-height: 24px; }
  .btn-prime,
  .btn-prime:link,
  .btn-prime:visited {
    font-size: 16px; }
  .sale-text {
    font-size: 22px; } }

.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-list {
  margin: 0 -10px; }

.slick-slide {
  margin: 0 10px; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 30px;
  cursor: pointer; }

/**********************
Navigation
**********************/
nav {
  padding: 0 !important; }
  nav .container {
    max-width: 1600px; }

nav .nav-item {
  padding: 0; }

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px !important; }

.buy-now {
  background-color: #2DA7DF !important;
  color: #fff !important; }

.navbar-dark .navbar-nav .nav-item,
.navbar-dark .navbar-nav .nav-item:active,
.navbar-dark .navbar-nav .nav-item:focus,
.navbar-dark .navbar-nav .nav-item:hover,
.navbar-dark .navbar-nav .nav-item:visited {
  color: #fff;
  font-size: 14px;
  outline: 0;
  letter-spacing: 0.05em;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }

.navbar-dark .navbar-nav .nav-link:hover {
  background-color: transparent;
  color: #2DA7DF; }

.navbar-brand:hover,
.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #2DA7DF; }
  .navbar-brand:hover img,
  .navbar-dark .navbar-brand img {
    max-width: 120px; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.dropdown-item {
  background-color: #fff; }

.dropdown-menu.show {
  background-color: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f8f8;
  color: #212529; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #2DA7DF; }

.cta-btn {
  background-color: #2DA7DF;
  line-height: inherit;
  margin-left: 50px; }
  .cta-btn:hover {
    color: white !important;
    background: #2DA7DF; }
  .cta-btn .nav-link {
    color: #fff !important; }

#main-nav {
  background-color: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.buy-now {
  background: #2DA7DF !important; }

/* Mobile Menu */
header {
  background-color: #fff;
  position: relative; }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  #main-nav {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a.buy-now {
    max-width: 300px;
    margin: 0 auto; }
  .transparent-header {
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.3); }
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #2DA7DF; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 18px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background-color: #000;
    box-shadow: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #fff;
    font-weight: 600;
    display: block;
    letter-spacing: 0.05em;
    padding: 15px;
    font-size: 22px;
    text-transform: uppercase; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 3000;
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    max-width: 100px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 17px; } }

.transparent-header {
  padding: 30px 0; }

/**********************
Hero
**********************/
.big-hero {
  background: url("../img/hero-bg.jpg") no-repeat right top;
  background-size: 45%;
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 700px;
  color: #000; }
  .big-hero .container {
    position: relative;
    z-index: 2; }
  .big-hero .row {
    padding: 0 15px; }
  .big-hero .section-title {
    color: #000;
    font-size: 100px;
    line-height: 1.1;
    margin-bottom: 16px; }
    .big-hero .section-title span {
      display: block; }
  .big-hero .sub-heading {
    line-height: 1.5;
    margin-bottom: 16px; }
  .big-hero .text-area {
    max-width: 550px; }
  .big-hero .img-container {
    max-width: 516px;
    margin: 0 0 20px; }
  .big-hero .cta-container {
    margin-top: 40px; }

@media (min-width: 2000px) {
  .big-hero {
    height: 900px; } }

@media (max-width: 2000px) {
  .big-hero .text-area {
    margin-top: 120px; } }

@media (max-width: 991px) {
  .big-hero {
    margin-top: 60px;
    padding: 0; }
  .big-hero .text-area {
    margin-top: 0; }
  .big-hero .section-title {
    font-size: 80px; } }

@media (max-width: 767px) {
  .big-hero {
    height: 500px; }
  .big-hero .section-title {
    font-size: 50px; } }

@media (max-width: 750px) {
  .big-hero .text-area {
    margin: 0 auto; }
  .big-hero .img-container {
    margin: 0 auto 20px; }
  .big-hero {
    position: relative;
    background-size: cover;
    text-align: center;
    height: auto;
    padding: 50px 0;
    color: #fff; }
    .big-hero .cta-container .schedule {
      -webkit-box-pack: center;
      justify-content: center; }
    .big-hero::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.5; }
  .big-hero .section-title {
    color: #fff;
    font-size: 50px;
    line-height: 1.5; }
  .big-hero .section-title span {
    display: inline; }
  .big-hero .sub-heading {
    color: #fff; } }

@media (max-width: 450px) {
  .big-hero .section-title {
    font-size: 40px; } }

@media (max-width: 375px) {
  .big-hero .section-title {
    font-size: 40px !important; } }

/******************************
Helping
******************************/
.helping .image img {
  width: 100%; }

.helping .companies {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px; }

@media (max-width: 991px) {
  .helping {
    text-align: center; }
    .helping .flex-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .helping .schedule {
      -webkit-box-pack: center;
      justify-content: center; }
    .helping .text-area {
      max-width: 700px;
      margin: 0 auto 50px; }
    .helping .companies img {
      margin: 0 auto; } }

/******************************
Video
******************************/
.video {
  text-align: center; }
  .video .section-title {
    margin-bottom: 0; }
  .video .pulse {
    margin-bottom: 30px; }
  .video .graphic-section {
    margin: 50px 0; }
  .video .main-video .video-container {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; }
  .video .main-video .img-container {
    position: relative; }
    .video .main-video .img-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0; }
  .video .main-video .play-btn {
    position: absolute;
    max-width: 80px;
    z-index: 1; }
  .video .cta-container {
    margin-top: 30px; }
    .video .cta-container .schedule {
      -webkit-box-pack: center;
      justify-content: center; }
  .video .sub-videos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-bottom: 50px; }

/******************************
Solutions
******************************/
.solutions {
  text-align: center;
  background: url("../img/solutions-bg.jpg") no-repeat center center;
  background-size: cover; }
  .solutions .section-header .section-title {
    margin-bottom: 0; }
  .solutions .grid-row {
    margin: 50px 0;
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 1fr; }
    .solutions .grid-row .item {
      background-color: #fff;
      padding: 20px;
      min-height: 300px;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between; }
      .solutions .grid-row .item .icon-box {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between; }
      .solutions .grid-row .item .title {
        font-family: "Bebas Regular";
        line-height: 1.4;
        margin-bottom: 0;
        font-size: 32px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center; }
        .solutions .grid-row .item .title .color {
          color: #F8992F; }
        .solutions .grid-row .item .title .inner {
          display: -webkit-box;
          display: flex;
          text-transform: uppercase; }
          .solutions .grid-row .item .title .inner.grey {
            color: #A9A8A9; }
      .solutions .grid-row .item .des {
        text-align: left; }
      .solutions .grid-row .item .icon-img {
        font-size: 35px;
        margin-bottom: 20px;
        color: #000; }
  .solutions .bottom {
    margin-top: auto; }
  .solutions .read-more {
    margin-top: 10px;
    padding: 20px 0;
    font-weight: 700;
    border-bottom: 1px solid #e8e8e8;
    color: #2DA7DF; }
    .solutions .read-more .link-inner {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center; }
  .solutions .cta-container {
    margin-top: 50px; }
    .solutions .cta-container .schedule .text {
      width: 100%; }

.modal-dialog {
  max-width: 800px; }

.modal {
  text-align: left; }
  .modal .modal-inner:not(:last-child) {
    margin-bottom: 40px; }
  .modal h5 {
    font-weight: 700; }
  .modal ul li:not(:last-child) {
    margin-bottom: 10px; }

@media (max-width: 1199px) {
  .solutions .grid-row .item {
    min-height: 250px; } }

@media (max-width: 991px) {
  .cta-container .schedule .icon {
    display: none; } }

@media (max-width: 450px) {
  .solutions .grid-row .item .title {
    font-size: 28px; } }

@media (max-width: 750px) {
  .solutions .grid-row {
    grid-template-columns: 1fr; }
  .solutions .grid-row .item .des {
    text-align: center; } }

/**********************
Technologies
*************************/
.technologies {
  text-align: center; }
  .technologies .section-header {
    margin-bottom: 40px; }
    .technologies .section-header .sub-heading {
      color: #000;
      margin-bottom: 30px; }
  .technologies .icons {
    margin: 30px 0 0; }
    .technologies .icons .top {
      margin-bottom: 25px; }
    .technologies .icons .number {
      color: #F8992F;
      font-size: 66px;
      font-family: "Bebas Regular"; }
    .technologies .icons .icon-title {
      font-family: "Bebas Regular";
      font-size: 34px; }
    .technologies .icons .icon-text span {
      color: #F8992F;
      font-weight: 700; }
  .technologies .schedule {
    -webkit-box-pack: center;
    justify-content: center; }

@media only screen and (max-width: 991px) {
  .technologies .icons {
    margin: 0; } }

@media only screen and (max-width: 767px) {
  .technologies .icon-container:not(:last-child) {
    margin-bottom: 20px; }
  .technologies .icons {
    max-width: 400px;
    margin: 0 auto; } }

@media only screen and (max-width: 600px) {
  .technologies .icons .icon-title {
    font-size: 28px; } }

/******************************
About
******************************/
.about {
  background-color: #fff5f1;
  padding: 0; }
  .about .section-header {
    margin-bottom: 20px; }

@media (max-width: 991px) {
  .about {
    text-align: center; }
    .about .img-container {
      margin-bottom: 40px; } }

/**********************
Buy Stream
**********************/
.purchase {
  text-align: center;
  background: #fff5f1; }
  .purchase .section-header {
    margin-bottom: 40px; }
  .purchase .grid-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 30px; }
    .purchase .grid-row .item {
      padding: 0;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
      .purchase .grid-row .item .img-container {
        margin: 20px 0; }
      .purchase .grid-row .item .license {
        color: #2DA7DF;
        letter-spacing: 1px;
        font-family: "Recoleta Bold";
        text-transform: uppercase; }
      .purchase .grid-row .item .price {
        font-weight: 700;
        font-size: 80px;
        color: #101D4F; }
        .purchase .grid-row .item .price span {
          text-decoration: line-through;
          opacity: 0.3; }
      .purchase .grid-row .item .des {
        padding: 15px; }
        .purchase .grid-row .item .des .title {
          font-weight: 700;
          color: #000; }
      .purchase .grid-row .item .cta-container {
        margin-top: auto;
        width: 100%; }
        .purchase .grid-row .item .cta-container .btn-prime {
          display: block; }

/*************************
Pricing Modal
*************************/
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  margin-top: 8px;
  margin-bottom: 28px; }

.bootstrap-select .dropdown-toggle .filter-option {
  border-radius: 0; }

.bootstrap-select > .dropdown-toggle {
  padding: 13px;
  border: solid 1px #101D4F; }

.bootstrap-select .dropdown-menu li a {
  padding: 10px 13px; }

.btn-light:hover {
  color: #fff;
  background-color: #2DA7DF;
  border-color: #2DA7DF; }

#popupModal {
  text-align: center;
  padding-right: 0 !important; }
  #popupModal button:focus {
    outline: 0; }
  #popupModal button.close {
    opacity: 1; }
  #popupModal a:not([href]):hover {
    color: #fff; }
  #popupModal .hide {
    display: none; }
  #popupModal .modal-content {
    border-radius: 0;
    border: 0;
    background-color: #f8f8f8; }
    #popupModal .modal-content .section-title {
      font-size: 24px; }
  #popupModal .modal-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #2DA7DF;
    background: #2DA7DF; }
    #popupModal .modal-header img {
      max-width: 120px; }
    #popupModal .modal-header .modal-header .close {
      margin: 0; }
  #popupModal .modal-title {
    font-size: 30px;
    font-weight: 400; }
  #popupModal .modal-body {
    background-color: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.08);
    border: none;
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    max-width: 90%;
    margin: 40px auto;
    padding: 40px 20px;
    border-radius: 10px; }
    #popupModal .modal-body .modal-price {
      padding: 15px;
      background: #101D4F;
      font-size: 40px;
      line-height: 1.2;
      color: #fff; }
    #popupModal .modal-body .modal-text {
      padding: 30px 15px; }
    #popupModal .modal-body .buy-license a {
      padding: 10px 25px;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      display: inline-block;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      vertical-align: middle;
      font-weight: 700;
      box-shadow: none !important;
      outline: 0;
      border: 2px solid #000;
      background-color: #000;
      color: #fff;
      display: block;
      border-radius: 0; }
  #popupModal .content .title {
    font-weight: bold;
    font-size: 17px;
    letter-spacing: 1px;
    border-radius: 8px 8px 0 0;
    margin-top: 0;
    margin-bottom: 10px; }
  #popupModal .content .license-container p {
    margin: 0; }
  #popupModal .content .license-container .box .header .price-head {
    background-color: #000;
    width: 100%;
    padding: 0;
    color: #fff;
    font-weight: 600;
    padding: 30px; }
    #popupModal .content .license-container .box .header .price-head .price {
      color: #fff;
      font-size: 40px; }
    #popupModal .content .license-container .box .header .price-head p {
      font-size: 35px;
      font-weight: bold;
      margin: 0; }
  #popupModal .content .license-container .box .body {
    padding: 15px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee; }
  #popupModal .content .license-container .box .card-footer {
    padding: 0;
    border-top: 0; }
    #popupModal .content .license-container .box .card-footer a {
      display: block;
      text-decoration: none;
      text-transform: uppercase; }

/**********************
reviews
**********************/
.reviews {
  color: #fff;
  background: url(../img/reviews-bg.jpg) no-repeat center center;
  background-attachment: fixed;
  background-size: cover;
  padding: 140px 0; }
  .reviews .section-title {
    color: #fff; }
  .reviews .text-area {
    position: relative; }
    .reviews .text-area::before {
      position: absolute;
      top: 0;
      left: -80px;
      content: url(../img/quote-left-solid.png);
      z-index: 1; }
    .reviews .text-area::after {
      position: absolute;
      bottom: 0;
      right: -80px;
      content: url(../img/quote-right-solid.png); }
  .reviews .section-header {
    margin-bottom: 60px;
    text-align: center; }
  .reviews .slick-track {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .reviews .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .reviews .product-next,
  .reviews .product-prev {
    cursor: pointer; }
  .reviews .quote {
    font-size: 28px;
    line-height: 1.5;
    font-weight: 700; }

@media only screen and (max-width: 991px) {
  .reviews {
    text-align: center; }
  .reviews .quote {
    font-size: 20px;
    line-height: 1.5; } }

@media only screen and (max-width: 600px) {
  .reviews {
    padding: 50px 0; }
  .reviews .item img {
    margin-bottom: 20px;
    max-width: 200px; }
  .reviews .quote {
    font-size: 16px; }
  .reviews .section-header {
    margin-bottom: 30px; } }

/**********************
FAQ
*************************/
.faq {
  text-align: center;
  font-size: 16px; }
  .faq .section-header {
    margin-bottom: 40px; }
  .faq .schedule {
    -webkit-box-pack: center;
    justify-content: center; }
  .faq .arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.rotate {
  -webkit-transform: rotate(0) !important;
  transform: rotate(0) !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg); } }

@-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

.card-header:first-child {
  border-radius: 0; }

.card:last-child .card-header {
  border-bottom: 1px solid #e8e8e8; }

.active-question {
  border-top: 0 !important;
  border-bottom: 0 !important; }

#questions {
  text-align: left; }
  #questions .card {
    border: 0;
    border-bottom: 1px solid #E8E8E8; }
    #questions .card:first-child {
      border-top: 0; }
    #questions .card .card-header {
      padding: 0;
      position: relative;
      background-color: #fff;
      border: 0; }
    #questions .card .card-body {
      border-bottom: 0;
      padding: 15px 0; }
  #questions .btn-link {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: left;
    color: #000;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px 0;
    white-space: inherit;
    font-size: 18px;
    font-weight: 900;
    cursor: pointer; }
    #questions .btn-link svg {
      position: absolute;
      top: 50%;
      right: 16px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); }

.collapsed.show {
  border-top: 0 !important;
  border-bottom: 0 !important; }

@media only screen and (max-width: 600px) {
  #questions .btn-link {
    font-size: 16px; } }

/**********************
Footer
**********************/
footer {
  background-color: #000;
  color: #fff;
  padding: 60px 0;
  font-weight: 500;
  font-size: 16px; }
  footer .brand {
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center; }
    footer .brand .copyright {
      margin-top: 10px;
      font-size: 12px; }
    footer .brand .img-container {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 100%; }
  footer .foot-item .links {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  footer a {
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    footer a:hover {
      color: #F8992F; }
  footer .footer_title {
    text-transform: uppercase;
    color: #F8992F;
    margin-bottom: 10px;
    font-family: "Bebas Regular";
    font-size: 24px; }
  footer .social {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
    footer .social svg {
      margin: 0 7px;
      font-size: 18px;
      color: #fff;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      footer .social svg:hover {
        color: #F8992F; }

@media only screen and (max-width: 1199px) {
  footer {
    text-align: center; }
  footer .col-xl-2 {
    -webkit-box-ordinal-group: 5;
    order: 4; }
  footer .brand .img-container {
    margin-top: 50px; } }

@media only screen and (max-width: 767px) {
  .foot-item {
    margin-bottom: 20px; } }

.section-header {
  margin-bottom: 50px; }

.designs .grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px; }

.designs .item {
  text-align: center;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #f8f8f8; }
  .designs .item .link-color {
    color: #000;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .designs .item .text-box {
    padding: 20px; }
    .designs .item .text-box .title {
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: capitalize; }
  .designs .item .img-container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    .designs .item .img-container img {
      border-radius: 20px 20px 0 0; }

.designs .page {
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.designs .page-active {
  display: block; }

.designs .pagination {
  margin-top: 80px;
  -webkit-box-pack: center;
  justify-content: center; }

@media (max-width: 767px) {
  .designs .grid-row {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 575px) {
  .designs .grid-row {
    grid-template-columns: 1fr; } }
